import React, { Component } from "react";
import "./Page.css";
import axios from "axios";
import VideoComponent from "./utils/player";
import MDSpinner from "react-md-spinner";
import { NavLink } from "react-router-dom";

class PlaylistPage extends Component {
  state = {
    playlist: {},
    video: {},
    isLoading: true,
    error: "",
    embed: false,
    isSearch: false,
    searchField: "",
    searchItems: {}
  };

  componentDidMount() {
    document.body.classList.toggle("animation", false);
    const { history, match } = this.props;

    let page;

    if (match.params && match.params.page) {
      page = match.params.page;
    } else {
      history.replace(match.url + "/1");
      page = 1;
    }

    console.log("Page: ", page);

    let url =
      "https://manager.myovn.com/get/account/" +
      match.params.username +
      "/playlists/" +
      match.params.playlistid +
      "/" +
      page;

    //check if is embed

    if (match.params.embed && match.params.embed === "embed") {
      this.setState({ embed: true });
    }

    axios
      .get(url)
      .then(res => {
        const playlist = res.data;

        document.title = playlist.title + " | transmite.live";

        let video = playlist.videos[0];
        video.owner = playlist.owner;

        this.setState({
          playlist,
          video,
          isLoading: false
        });
      })
      .catch(function() {
        history.push("/");
      });
  }

  getSearch = () => {
    console.log("clicked");
    this.setState({ isSearch: true, isLoading: true });
    const { match } = this.props;

    let searchUrl =
      "https://manager.myovn.com/get/account/" +
      match.params.username +
      "/search/" +
      match.params.playlistid +
      "/" +
      this.state.searchField;

    axios
      .get(searchUrl)
      .then(res => {
        const seachList = res.data;

        let video = seachList.videos[0];
        video.owner = seachList.owner;

        this.setState({
          isLoading: false,
          video,
          error: "",
          searchItems: seachList
        });
      })
      .catch(() => {
        this.setState({
          isSearch: false,
          isLoading: false,
          searchField: "",
          video: this.state.playlist.videos[0],
          error: "No video was found. Please try another search word!"
        });
      });
  };

  getReset = () => {
    this.setState({
      isSearch: false,
      isLoading: false,
      searchField: "",
      video: this.state.playlist.videos[0]
    });
  };

  onChange = event => {
    //console.log(event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  getPoster = (image, storage, type) => {
    if (image) {
      return "//" + storage + ".myovn.com/" + image;
    } else {
      return "//myovn.com/play.png";
    }
  };

  setVideo = video => {
    let nextVideo = video;
    nextVideo.owner = this.state.playlist.owner;
    this.setState({ video: nextVideo });
  };

  renderSearch = () => {
    return (
      <div
        className="col-md-12 card searchBarWrap"
        style={{ marginTop: "1rem" }}
      >
        <div className="input-group">
          <input
            type="text"
            value={this.state.searchField}
            className="form-control"
            placeholder="Search videos"
            name="searchField"
            onChange={this.onChange}
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button
              className="btn chatButtonAdd"
              type="button"
              onClick={this.getSearch}
            >
              Search
            </button>
            <button
              className="btn chatButtonAdd"
              type="button"
              onClick={this.getReset}
              disabled={!this.state.isSearch}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderMedia = videos => {
    //console.log(videos);

    return (
      <div className="col-md-12 card text-left" style={{ marginTop: "1rem" }}>
        {this.state.isSearch && (
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">
                Found {this.state.searchItems.videos.length} videos with
                <strong> {this.state.searchField}</strong>
              </p>
            </div>
          </div>
        )}

        {this.state.error && (
          <div className="row">
            <div className="col-md-12">
              <p className="text-center">{this.state.error}</p>
            </div>
          </div>
        )}
        <div className="row">
          {videos.map(item => {
            return this.renderMediaItem(item);
          })}
        </div>
      </div>
    );
  };

  renderMediaItem = item => {
    return (
      <div
        key={item._id}
        className="col-md-3 col-xs-6"
        onClick={() => this.setVideo(item)}
      >
        <div className="card mediaItem">
          <img
            className="card-img"
            src={this.getPoster(item.image, "s1")}
            alt=""
          />
          <div className="mediaTitle">{item.title}</div>
          {item._id === this.state.video._id && (
            <span className="currentVideo">now playing</span>
          )}

          {item.type === "live" && <span className="liveVideo">LIVE</span>}
        </div>
      </div>
    );
  };

  renderVideo = () => {
    const { playlist, embed, video, searchItems } = this.state;

    return (
      <React.Fragment>
        <div className="col-md-12 card " style={{ paddingBottom: 0 }}>
          <h3 style={{ opacity: 0.5 }}>{playlist.title}</h3>
          <hr />
          <div className="row ">
            <div className="col-md-8 " style={{ paddingBottom: "1rem" }}>
              <VideoComponent video={video} embed={embed} />
            </div>
            <div className="col-md-4  text-left">
              <h6>{video.title}</h6>
              <hr />
              <p style={{ opacity: 0.5 }}>{video.description}</p>
            </div>
            {this.renderSearch()}
          </div>
        </div>

        {!this.state.isSearch && this.renderMedia(playlist.videos)}
        {!this.state.isSearch && this.renderPagination()}

        {this.state.isSearch && this.renderMedia(searchItems.videos)}
      </React.Fragment>
    );
  };

  createURL = (url, page, embed) => {
    let link = url + page;
    if (embed) {
      link = link + "/embed";
    }
    return link;
  };

  renderPagination = () => {
    const perPage = 8;
    const totalItems = this.state.playlist.size;
    const pages = Math.round(totalItems / perPage);
    var pagesArray = [];

    var url =
      "/" +
      this.props.match.params.username +
      "/playlist/" +
      this.props.match.params.playlistid +
      "/";
    for (var i = 0; i < pages; i++) {
      pagesArray.push(
        <li className="page-item" key={i}>
          <NavLink
            to={this.createURL(url, i + 1, this.state.embed)}
            className="page-link"
          >
            <span>{i + 1}</span>
          </NavLink>
        </li>
      );
    }

    const previousPage = (
      <li
        className={
          "page-item " +
          (this.props.match.params.page === "1" ? "disabled" : "")
        }
      >
        <NavLink
          to={this.createURL(
            url,
            this.props.match.params.page - 1,
            this.state.embed
          )}
          className="page-link"
        >
          <span>Previous</span>
        </NavLink>
      </li>
    );
    const nextPage = (
      <li
        className={
          "page-item " +
          (this.props.match.params.page >= pages ? "disabled" : "")
        }
      >
        <NavLink
          to={this.createURL(
            url,
            Number(this.props.match.params.page) + 1,
            this.state.embed
          )}
          className="page-link"
        >
          <span>Next</span>
        </NavLink>
      </li>
    );

    if (pages === 1) {
      return;
    }
    return (
      <nav className="paginationWrap">
        <ul className="pagination justify-content-center">
          {previousPage}
          {pagesArray}
          {nextPage}
        </ul>
      </nav>
    );
  };

  render() {
    const { isLoading, embed } = this.state;
    const LoadingHtml = (
      <div className="alignCenter" style={{ height: "100vh" }}>
        <div className="text-center">
          <MDSpinner size={60} borderSize={10} />
          <br />
          <br />
          <h5>Loading video. Please wait.</h5>
        </div>
      </div>
    );

    // if (embed) {
    //   return (
    //     <React.Fragment>
    //       {isLoading && LoadingHtml}
    //       {!isLoading && <VideoComponent video={video} embed={embed} />}
    //     </React.Fragment>
    //   );
    // }

    if (isLoading) {
      return LoadingHtml;
    }
    if (!embed) {
      return (
        <div className="container h-100">
          <div className="row pageWrap">{this.renderVideo()}</div>
        </div>
      );
    } else {
      return <div className="row pageWrap">{this.renderVideo()}</div>;
    }
  }
}

export default PlaylistPage;
