import React, { Component } from "react";

import {
  PlayerCSS,
  ClapprPanelLogo,
  LiveViewsCount,
  LevelSelector,
} from "./playerSkin/playerSkin";
import ChromecastPlugin from "clappr-chromecast-plugin";
import ClapprStats from "clappr-stats";

import Clappr from "clappr";

import socketIOClient from "socket.io-client";
import ReactPlayer from "react-player";

import queryString from "query-string";
import axios from "axios";

let player = null;
let socket;
class VideoComponent extends Component {
  shouldComponentUpdate(nextProps) {
    let changed = nextProps.video.video !== this.props.video.video;

    // console.log("new video? ", changed);

    if (changed) {
      // console.log("before update");

      this.destroyPlayer();

      this.props = nextProps;
      if (!this.isExternal()) {
        this.change(nextProps);
      }
      return true;
    }

    return false;
  }

  componentDidMount() {
    if (!this.isExternal()) {
      this.change(this.props);
    }

    //socket data 25.05.2020
    if (
      this.props.video.type === "live" &&
      this.props.video.enable_public_views
    ) {
      socket = socketIOClient("radar.myovn.com");
      socket.emit("hello", {
        room: this.props.video._id,
        msg: false,
      });
      // socket.on("hello", data => console.log(data));
      socket.on("welcome", (data) => console.log(data));
      socket.on("roomCount", (data) => {
        //if you want to show total viewers
        if (this.props.video.public_views) {
          player &&
            player.getPlugin().setLiveCount({
              count: data,
            });
        }
      });
    }
  }

  isExternal = () => {
    // console.log("video Type: ", this.props.video.type);
    switch (this.props.video.type) {
      case "facebook":
      case "youtube":
      case "vimeo":
        return true;
      case "live":
      case "recording":
      case "local":
        return false;
      default:
        return true;
    }
  };

  componentWillUnmount() {
    this.destroyPlayer();
  }

  destroyPlayer = () => {
    if (player) {
      player.destroy();
    }
    player = null;
  };

  getPoster = (image, storage) => {
    if (image) {
      return "//" + storage + ".myovn.com/" + image + "?time=" + Date.now();
    } else {
      return "//myovn.com/play.png";
    }
  };

  getSource = (video, error = false) => {
    let playURL = "eu.myovn.com";

    if (
      video.loadbalancer &&
      sessionStorage.getItem("loadbalancer") &&
      !error
    ) {
      playURL = sessionStorage.getItem("loadbalancer");
      console.log("set loadbalancer:", playURL);
    }

    if (error) {
      console.log("default stream server:", playURL);
    }

    switch (video.type) {
      case "channel":
        return (
          "//" +
          playURL +
          video.owner.storage +
          "/cache/" +
          video.c_name +
          "/playlist.m3u8"
        );

      case "live":
        if (video.smil) {
          // return (
          //   "//play.myovn.com/" +
          //   video.owner.storage +
          //   "/cache/smil:" +
          //   video.video +
          //   ".smil/playlist.m3u8"
          // );

          //redirect to s0.myovn.com for multiple versions
          return "//" + playURL + "/hls/" + video.video + ".m3u8";
        } else {
          return (
            "//" +
            playURL +
            "/" +
            video.owner.storage +
            "/cache/" +
            video.video +
            "/playlist.m3u8"
          );
        }

      case "recording":
        return (
          "//" +
          playURL +
          "/" +
          video.owner.storage +
          "/vod/mp4:" +
          video.video +
          ".mp4/playlist.m3u8"
        );
      case "local":
        return (
          "//" +
          playURL +
          "/" +
          video.owner.storage +
          "/vod/smil:" +
          video.video +
          ".smil/manifest.m3u8"
        );
      case "youtube":
        return "//www.youtube.com/watch?v=" + video.video;
      case "facebook":
        return "//facebook.com" + video.fb_permalink;
      case "vimeo":
        return "//vimeo.com/" + video.video;

      default:
        return null;
    }
  };

  change = (props) => {
    const { video, embed } = props;

    if (player) {
      this.destroyPlayer();

      // player.configure({
      //   autoPlay: true,
      //   poster: this.getPoster(video.image, video.owner.storage)
      // });

      // player.load(this.getSource(video));
      // console.log("dupa nebunie", player);

      // return;
    }

    var ErrorPlugin = Clappr.ContainerPlugin.extend({
      name: "error_plugin",
      background:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAFoBAMAAAA1HFdiAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAG1BMVEX5+fn//wAA//8A/wD/AP//AAAAAP8XFxf///8H5gWfAAAAAWJLR0QIht6VegAAAAd0SU1FB98IBRIsAXmGk48AAAI5SURBVHja7dJBDYBADADBs4AFLGABC1iohbOPhv1BMvu+NLlp10odqTN1pe7Uk5pQ8wMIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECBAgAABAgQIECDA/wKWxzM71T7ZZrfltNnppgACBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAAECBAgQIECAAL8B+ALjSfYzPnmdzgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxNS0wOC0wNVQxODo0NDowMSswMTowMCL95a4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTUtMDgtMDVUMTg6NDQ6MDErMDE6MDBToF0SAAAAAElFTkSuQmCC",
      bindEvents: function () {
        this.listenTo(
          this.container,
          Clappr.Events.CONTAINER_ERROR,
          this.onError
        );
      },

      hide: function () {
        this._err && this._err.remove();
        // this.container.$el.find(".play-wrapper").show();
      },
      show: function () {
        var $ = Clappr.$;
        let poster = this.container.$el.find(".play-wrapper").hide();
        // console.log(poster);
        this.hide();
        var txt =
          this.options.errorPlugin && this.options.errorPlugin.text
            ? this.options.errorPlugin.text
            : "Starting soon";
        this._err = $("<div>")
          .css({
            position: "absolute",
            "z-index": "999",
            // width: "100%",
            // height: "100%",
            color: "#000",
            // "background-image": "url(" + this.background + ")",
            // "background-size": "contain",
            // "background-repeat": "no-repeat",
            // "padding-top": "15%",
            "text-align": "center",
            "font-weight": "bold",
            background: "rgba(0,0,0,0.7)",
            bottom: "44%",
            left: "1%",
            display: "flex",
            "align-items": "center",
            "justify-content": "center",
            padding: "1%",

            "flex-direction": "column",
            "border-radius": 6,
            "min-width": "30%",

            // "text-shadow": "1px 1px #fff",
          })
          .append(
            $("<h2>").text(txt).css({
              "font-size": "140%",
              color: "#fff",
            })
          )
          .append(
            $("<p>")
              .html(
                // 'Retrying in <span class="retry-counter">10</span> seconds ...'
                "Please wait for video feed."
              )
              .css({
                "font-size": "70%",

                color: "#fff",
                opacity: "0.7",
              })
          );
        this.container && this.container.$el.prepend(this._err);
      },
      onError: function (e) {
        if (!this.container || this._err) return;

        console.log();

        this.show();
        this.container.getPlugin("click_to_pause").disable();
        this.container.mediaControlDisabled = false;
        this.container.isReady = false;
        // console.log(this.container);

        console.log();
        var tid,
          t = 10,
          retry = function () {
            clearTimeout(tid);
            if (t === 0) {
              this.container.getPlugin("click_to_pause").enable();
              if (
                this.options.errorPlugin &&
                this.options.errorPlugin.onRetry
              ) {
                this.options.errorPlugin.onRetry(e);
                return;
              } else {
                this.container.stop();
                this.container.play();
                return;
              }
            }
            // Clappr.$(".retry-counter").text(t);
            t--;
            tid = setTimeout(retry, 1000);
          }.bind(this);
        retry();
      },
    });

    let playerPlugins = [
      ChromecastPlugin,
      LevelSelector,
      PlayerCSS,
      ErrorPlugin,
      ClapprStats,
      // LiveViewsCount,
      // ClapprPanelLogo,
    ];

    //disabled for 2020
    // if (embed) {
    //   playerPlugins.push(ClapprPanelLogo);
    // }

    if (video.public_views) {
      playerPlugins.push(LiveViewsCount);
    }

    // console.log("Clappr version " + Clappr.version);

    // Clappr.Log.setLevel(0);

    var r = 5; // Retry attempts

    let that = this;
    let p2p = video.p2p;
    let p2p_playback = "";
    let p2p_ready = false;

    let playback_options = {
      preload: "metadata",
      playInline: true,
    };

    // console.log("p2p enabled",p2p);

    if (p2p && window.p2pml.hlsjs.Engine.isSupported()) {
      console.log("p2p is supported");
      p2p_ready = true;

      const p2p_config = {
        loader: {
          trackerAnnounce: ["wss://p2p.myovn.com"],
          httpUseRanges: false,
          WaitForTracker: true,
          WaitForTrackerCounter: 4500,
        },
        // segments: {
        //   swarmId: "CustomSwarmId"
        // }
      };
      var engine = new window.p2pml.hlsjs.Engine(p2p_config);
      playback_options.hlsjsConfig = {
        liveSyncDurationCount: 7,
        loader: engine.createLoaderClass(),
      };
    } else {
      console.log("p2p is NOT supported");
    }

    // console.log("location?", queryString.parse(this.props.location));

    async function myFunction(metrics) {
      let trackData = queryString.parse(props.location);
      if (trackData.track && trackData.track == "1") {
        console.log("user:", trackData.user);
        console.log("time: " + Math.ceil(metrics.timers.watch / 1000));
        // console.log(
        //   "time: " +
        //     new Date(Math.round(metrics.timers.watch))
        //       .toISOString()
        //       .substr(11, 8)
        // );

        await axios
          .get(
            "https://ey1.sptv.net/users/" +
              trackData.user +
              "/watch/" +
              Math.round(metrics.timers.watch / 1000) +
              "/" +
              trackData.session
          )
          .then((res) => {
            // console.log(Math.round(metrics.timers.watch / 1000));
          });
      }
    }

    player = new Clappr.Player({
      // baseUrl: "/assets/clappr",
      persistConfig: false,
      autoSeekFromUrl: false,
      gaAccount: "UA-38562707-11",
      gaTrackerName: "VideoPlayer",
      useHardwareVideoDecoder: true,
      playback: playback_options,
      // hideMediaControl: false,
      autoStartLoad: true,
      parentId: "#clp_player",
      poster: {
        url: this.getPoster(video.image, video.owner.storage),
        showOnVideoEnd: false,
      },
      source: this.getSource(video),
      autoPlay: video.owner.branding.b_player_autostart === true ? true : false,
      chromeless:
        video.owner.branding.b_player_controls === true ? false : true,
      disableVideoTagContextMenu: false,
      disableErrorScreen: true,
      watermark:
        video.owner.branding_enabled === true
          ? video.owner.branding.b_logo_url
          : "",
      position: video.owner.branding.b_logo_position,
      watermarkLink: video.owner.branding.b_logo_link,
      mute: video.owner.branding.b_player_volume === true ? true : false,
      width: "100%",
      height: "100%",

      errorPlugin: {
        text: "Starting Soon",
        onRetry: function (e) {
          // simulate successful recovery
          // or decide here what to do between each retry

          console.log("retry", r);
          r--;
          player.configure({
            source: r > 0 ? that.getSource(video) : that.getSource(video, true),
            autoPlay: true,
          });
        },
      },

      plugins: playerPlugins.concat(playerPlugins),

      levelSelectorConfig: {
        title: "Quality",
        labels: {
          3: "HD",
          2: "High",
          1: "Mid", // 240kbps
          0: "Low", // 120kbps
        },
        onLevelsAvailable: function (levels) {
          // console.log(levels);
          return levels.reverse(); // For example, reverse levels order
        },
      },

      // liveProgramMediaControl: {
      //   program: {
      //     title: "TITLE PROGRAM",
      //     startAt: "15:45",
      //     endAt: "16:30",
      //     progress: "50",
      //   },
      //   progressColor: "#ff6600",
      //   paddingWidth: 150,
      // },

      chromecast: {
        appId: "F71D1212", //transmite.live CAST App ID   - F71D1212  default 9DFB77C0
        media: {
          title: video.title,
          subtitle: video.description,
        },
      },
      clapprStats: {
        runEach: 10000,
        onReport: myFunction,
      },
      events: {
        onReady: function () {},
        onPlay: function () {},
      },
    });

    if (p2p_ready) {
      // console.log("p2p loading");
      window.p2pml.hlsjs.initClapprPlayer(player);
      engine.on("peer_connect", (peer) =>
        console.log("peer_connect", peer.id, peer.remoteAddress)
      );
      engine.on("peer_close", (peerId) => console.log("peer_close", peerId));
      engine.on("segment_loaded", (segment, peerId) =>
        console.log(
          "segment_loaded from",
          peerId ? `peer ${peerId}` : "HTTP",
          segment.url
        )
      );
    }

    //if player is not muted play at 100%
    if (!video.owner.branding.b_player_volume) {
      player.setVolume(100);
    }

    // console.log(player.options.source);
    // player.getPlugin().setLiveProgram({
    //   title: "LIVE Users 0",
    //   startAt: "15:45",
    //   endAt: "16:30",
    //   progress: "50",
    // });
    // this.player.getPlugin("click_to_pause").disable();
    // this.player.configure({
    //   poster: this.getPoster(video.image, video.owner.storage)
    // });

    // console.log("DVR?", this.getPoster(video.image, video.owner.storage));
  };
  render() {
    if (this.props.video.state !== "complete") {
      return (
        <div className="embed-responsive embed-responsive-16by9">
          <div className="embed-responsive-item alignCenter">
            <center>
              <h4>This video is converting.</h4>Please check again later.
            </center>
          </div>
        </div>
      );
    }

    return (
      <div className="embed-responsive   embed-responsive-16by9">
        {/* {!this.isExternal() && ( */}
        <div
          // id={"video_" + this.props.video._id}
          id="clp_player"
          className="embed-responsive-item"
        />
        {/* )} */}

        {this.isExternal() && (
          <ReactPlayer
            url={this.getSource(this.props.video)}
            className="embed-responsive-item"
            width="100%"
            height="100%"
            controls={true}
          />
        )}
      </div>
    );
  }
}

export default VideoComponent;
