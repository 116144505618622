// import React, { Component } from "react";
import {
  UICorePlugin,
  // Utils,
  MediaControl,
  Styler,
  template,
  // UIContainerPlugin,
  Events,
  Browser,
  $,
} from "clappr";

import controlsStyle from "./playerSkin.scss";
import liveProgram from "./liveProgram.scss";
import LiveViewsCountCss from "./liveViewsCount.scss";

// import pluginHtml from "./public/level-selector.html";
import pluginStyle from "./public/level-style.scss";

import style from "./public/style.css";
import ovn_white from "./../../../ovn_white.svg";

const AUTO = -1;

export class LevelSelector extends UICorePlugin {
  static get version() {
    return VERSION; // eslint-disable-line
  }

  get name() {
    return "level_selector";
  }
  get template() {
    return template(`<button data-level-selector-button>
    Auto
  </button>
  <ul>
    <% if (title) { %>
    <li data-title><%= title %></li>
    <% }; %>
    <li><a href="#" data-level-selector-select="-1">AUTO</a></li>
    <% for (var i = 0; i < levels.length; i++) { %>
    <li>
      <a href="#" data-level-selector-select="<%= levels[i].id %>"
        ><%= levels[i].label %></a
      >
    </li>
    <% }; %>
  </ul>
  `);
  }

  get attributes() {
    return {
      class: this.name,
      "data-level-selector": "",
    };
  }

  get events() {
    return {
      "click [data-level-selector-select]": "onLevelSelect",
      "click [data-level-selector-button]": "onShowLevelSelectMenu",
    };
  }

  get container() {
    return this.core.activeContainer
      ? this.core.activeContainer
      : this.core.mediaControl.container;
  }

  get playback() {
    return this.core.activePlayback
      ? this.core.activePlayback
      : this.core.getCurrentPlayback();
  }

  bindEvents() {
    this.listenTo(this.core, Events.CORE_READY, this.bindPlaybackEvents);
    if (Events.CORE_ACTIVE_CONTAINER_CHANGED)
      this.listenTo(
        this.core,
        Events.CORE_ACTIVE_CONTAINER_CHANGED,
        this.reload
      );
    else
      this.listenTo(
        this.core.mediaControl,
        Events.MEDIACONTROL_CONTAINERCHANGED,
        this.reload
      );
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this.render
    );
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_HIDE,
      this.hideSelectLevelMenu
    );
  }

  bindPlaybackEvents() {
    if (!this.playback) return;

    this.listenTo(
      this.playback,
      Events.PLAYBACK_LEVELS_AVAILABLE,
      this.fillLevels
    );
    this.listenTo(
      this.playback,
      Events.PLAYBACK_LEVEL_SWITCH_START,
      this.startLevelSwitch
    );
    this.listenTo(
      this.playback,
      Events.PLAYBACK_LEVEL_SWITCH_END,
      this.stopLevelSwitch
    );
    this.listenTo(
      this.playback,
      Events.PLAYBACK_BITRATE,
      this.updateCurrentLevel
    );

    let playbackLevelsAvailableWasTriggered =
      this.playback.levels && this.playback.levels.length > 0;
    playbackLevelsAvailableWasTriggered &&
      this.fillLevels(this.playback.levels);
  }

  reload() {
    this.stopListening();
    // Ensure it stop listening before rebind events (avoid duplicate events)
    process.nextTick(() => {
      this.bindEvents();
      this.bindPlaybackEvents();
    });
  }

  shouldRender() {
    if (!this.container || !this.playback) return false;

    let respondsToCurrentLevel = this.playback.currentLevel !== undefined;
    // Only care if we have at least 2 to choose from
    let hasLevels = !!(this.levels && this.levels.length > 1);

    return respondsToCurrentLevel && hasLevels;
  }

  render() {
    if (this.shouldRender()) {
      let style = Styler.getStyleFor(pluginStyle, {
        baseUrl: this.core.options.baseUrl,
      });

      this.$el.html(
        this.template({ levels: this.levels, title: this.getTitle() })
      );
      this.$el.append(style);
      this.core.mediaControl.$(".media-control-right-panel").append(this.el);
      this.$(".level_selector ul").css(
        "max-height",
        this.core.el.offsetHeight * 0.8
      );
      this.highlightCurrentLevel();
    }
    return this;
  }

  fillLevels(levels, initialLevel = AUTO) {
    if (this.selectedLevelId === undefined) this.selectedLevelId = initialLevel;

    let onLevelsAvailable =
      this.core.options &&
      this.core.options.levelSelectorConfig &&
      this.core.options.levelSelectorConfig.onLevelsAvailable;
    if (onLevelsAvailable) {
      if (typeof onLevelsAvailable === "function")
        levels = onLevelsAvailable(levels.slice());
      else throw new TypeError("onLevelsAvailable must be a function");
    }

    this.levels = levels;
    this.configureLevelsLabels();
    this.render();
  }

  configureLevelsLabels() {
    if (this.core.options.levelSelectorConfig === undefined) return;

    let labelCallback = this.core.options.levelSelectorConfig.labelCallback;
    if (labelCallback && typeof labelCallback !== "function")
      throw new TypeError("labelCallback must be a function");

    let hasLabels = this.core.options.levelSelectorConfig.labels;
    let labels = hasLabels ? this.core.options.levelSelectorConfig.labels : {};

    if (labelCallback || hasLabels) {
      let level;
      let label;
      for (let levelId in this.levels) {
        level = this.levels[levelId];
        label = labels[level.id];
        if (labelCallback) level.label = labelCallback(level, label);
        else if (label) level.label = label;
      }
    }
  }

  findLevelBy(id) {
    let foundLevel;
    this.levels.forEach((level) => {
      if (level.id === id) foundLevel = level;
    });
    return foundLevel;
  }

  onLevelSelect(event) {
    this.selectedLevelId = parseInt(
      event.target.dataset.levelSelectorSelect,
      10
    );
    if (this.playback.currentLevel === this.selectedLevelId) return false;
    this.playback.currentLevel = this.selectedLevelId;

    this.toggleContextMenu();

    event.stopPropagation();
    return false;
  }

  onShowLevelSelectMenu() {
    this.toggleContextMenu();
  }

  hideSelectLevelMenu() {
    this.$(".level_selector ul").hide();
  }

  toggleContextMenu() {
    this.$(".level_selector ul").toggle();
  }

  buttonElement() {
    return this.$(".level_selector button");
  }

  levelElement(id) {
    return this.$(
      ".level_selector ul a" +
        (!isNaN(id) ? '[data-level-selector-select="' + id + '"]' : "")
    ).parent();
  }

  getTitle() {
    return (this.core.options.levelSelectorConfig || {}).title;
  }

  startLevelSwitch() {
    this.buttonElement().addClass("changing");
  }

  stopLevelSwitch() {
    this.buttonElement().removeClass("changing");
  }

  updateText(level) {
    if (level === AUTO)
      this.buttonElement().text(
        this.currentLevel ? "AUTO (" + this.currentLevel.label + ")" : "AUTO"
      );
    else this.buttonElement().text(this.findLevelBy(level).label);
  }

  updateCurrentLevel(info) {
    let level = this.findLevelBy(info.level);
    this.currentLevel = level ? level : null;
    this.highlightCurrentLevel();
  }

  highlightCurrentLevel() {
    this.levelElement().removeClass("current");
    this.currentLevel &&
      this.levelElement(this.currentLevel.id).addClass("current");
    this.updateText(this.selectedLevelId);
  }
}

export class ClapprPanelLogo extends UICorePlugin {
  get name() {
    return "clappr-panel-logo";
  }

  static get version() {
    return VERSION; // eslint-disable-line
  }

  get attributes() {
    return {
      class: this.name,
    };
  }

  get stylesheet() {
    return Styler.getStyleFor(style);
  }

  get template() {
    return template(`<div>
    <a href=<%= link %> target="_blank">
      <img src=<%= src %> alt="OVN Player"/>
    </a>
  </div>`);
  }

  bindEvents() {
    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this.render
    );
  }

  render() {
    const { src = ovn_white, link = "//www.ovn.ro" } =
      this.options.clapprPanelLogo || {};

    this.$el.html(this.template({ src, link }));
    // this.core.mediaControl.$(".media-control-right-panel").append(this.$el);

    var $rightPannel = this.core.mediaControl.$(".media-control-right-panel");
    var $fsBtn = $rightPannel
      .find(".media-control-button.media-control-icon")
      .first();

    if ($fsBtn) {
      $fsBtn.before(this.el);
      //   console.log("before");
    } else {
      $rightPannel.append(this.el);
      //   console.log("after");
    }

    return this;
  }
}

export class PlayerCSS extends MediaControl {
  get stylesheet() {
    return Styler.getStyleFor(controlsStyle);
  }

  get isLiveWithoutDVR() {
    return (
      this.container &&
      this.container.getPlaybackType() === "live" &&
      !this.container.isDvrInUse()
    );
  }

  renderSeekBar() {
    // console.log("dvr?222", this.container.getPlaybackType());
    // console.log(this.isLiveWithoutDVR);

    if (this.isLiveWithoutDVR) {
      this.$seekBarScrubber.hide();
      this.$seekBarContainer.hide();
    }

    super.renderSeekBar();
  }
}

/**
 * A live stream only mediacontrol which display current program.
 * @class LiveProgramMediaControl
 * @constructor
 * @extends MediaControl
 */

export class LiveViewsCount extends UICorePlugin {
  constructor(core) {
    super(core);
    this._LiveViews = 0;
  }

  _setCount(count) {
    !count && (this._LiveViews = "");
    this._LiveViews = count;
  }

  setLiveCount(data) {
    // console.log("new count data", data.count);
    this._setCount(data.count);
    this.$count.html(this._LiveViews);
    return this;
  }

  renderLiveViews() {
    this.$layer = this.core.mediaControl.$(".media-control-layer");

    this.$count = $("<div />")
      .addClass("live-views-count")
      .attr("data-media-control", "");

    this.$eye = $("<div />").addClass("eye").attr("data-media-control", "");

    this.$liveProgram = $("<div />")
      .addClass("live-views")
      .attr("data-media-control", "");

    this.$liveProgram.append(this.$eye);
    this.$liveProgram.append(this.$count);

    this.$layer
      .find(".media-control-left-panel[data-media-control]")
      .append(this.$liveProgram);
    this.$layer.append(Styler.getStyleFor(LiveViewsCountCss));
    this.$count.html(this._LiveViews);
  }

  // @override
  bindEvents() {
    super.bindEvents();

    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this.render
    );
  }

  // @override
  render() {
    super.render();
    // console.log("render?");
    this.renderLiveViews();

    return this;
  }
}

export class LiveProgramMediaControl extends UICorePlugin {
  constructor(core) {
    super(core);
    this._seekBarHidden = false;
    this._setSeekBarHidden = !Browser.isiOS; // Fix seekbar scrubber on iPhone
    this._leftPanelWidth = 0;
    this._rightPanelWidth = 0;
    this._liveProgramLeft = 0;
    this._liveProgramWidth = 0;

    let pluginOpts = this.options.liveProgramMediaControl;
    console.log(pluginOpts);
    this._setProgram(pluginOpts.program);
    this._progressColor = (pluginOpts && pluginOpts.progressColor) || null;
    this._paddingWidth = (pluginOpts && pluginOpts.paddingWidth) || 18;
  }

  _setProgram(program) {
    !program && (program = {});
    this._program = {};
    this._program.title = program.title || "";
    this._program.startAt = program.startAt || "00:00";
    this._program.endAt = program.endAt || "00:00";
    this._program.progress = program.progress || 0;
  }

  // get isLiveWithoutDVR() {
  //   return this.container && this.container.getPlaybackType() === Playback.LIVE && !this.container.isDvrInUse()
  // }

  resizeLiveProgram(size) {
    if (!this.$liveProgram) {
      return;
    }

    // Get left mediacontrol panel width (with fixed padding)
    const lpw = this.$leftPanel.width();
    if (lpw > 0) {
      this._leftPanelWidth = lpw + this._paddingWidth;
    }

    // Get right mediacontrol panel width (with fixed padding)
    const rpw = this.$rightPanel.width();
    if (rpw > 0) {
      this._rightPanelWidth = rpw + this._paddingWidth;
    }

    // Calculate live program bar position and width
    const layerWidth = this.$layer.width();
    if (layerWidth > 0 && this._leftPanelWidth > 0) {
      const w = layerWidth - this._leftPanelWidth - this._rightPanelWidth;
      if (w > 0) {
        this._liveProgramLeft = this._leftPanelWidth;
        this._liveProgramWidth = w;
      }
    }

    // Update live program bar
    if (
      this._liveProgramLeft > 0 &&
      this._liveProgramWidth > 0 &&
      this._liveProgramWidth !== this.$liveProgram.width()
    ) {
      this.$liveProgram.css({
        left: `${this._liveProgramLeft}px`,
        width: `${this._liveProgramWidth}px`,
      });
    }
  }

  renderLiveProgramBar() {
    // Live program bar is rendered only if playback is live without DVR
    // if (!this.isLiveWithoutDVR) {
    //   return
    // }

    console.log("start render");

    this.$layer = this.core.mediaControl.$(".media-control-layer");

    this.$progressBar = $("<div />")
      .addClass("live-program-progress-bar")
      .attr("data-media-control", "");
    this.$progressBarBg = $("<div />")
      .addClass("live-program-progress-bar-bg")
      .attr("data-media-control", "");
    this.$progressBarFill = $("<div />")
      .addClass("live-program-progress-bar-fill")
      .attr("data-media-control", "");

    this._progressColor &&
      this.$progressBarFill.css({ "background-color": this._progressColor });

    this.$progressBar.append(this.$progressBarBg);
    this.$progressBar.append(this.$progressBarFill);

    this.$liveProgramBar = $("<div />")
      .addClass("live-program-bar")
      .attr("data-media-control", "");
    this.$liveProgramBar.append(this.$progressBar);

    this.$startAt = $("<div />")
      .addClass("live-program-start")
      .attr("data-media-control", "");
    this.$endAt = $("<div />")
      .addClass("live-program-end")
      .attr("data-media-control", "");

    this.$liveProgram = $("<div />")
      .addClass("live-program")
      .attr("data-media-control", "");
    this.$liveProgram.append(this.$startAt);
    this.$liveProgram.append(this.$liveProgramBar);
    this.$liveProgram.append(this.$endAt);

    this.$layer
      .find(".media-control-center-panel[data-media-control]")
      .append(this.$liveProgram);
    this.$layer.append(Styler.getStyleFor(liveProgram));

    this.$leftPanel = this.$layer.find(
      ".media-control-left-panel[data-media-control]"
    );
    this.$rightPanel = this.$layer.find(
      ".media-control-right-panel[data-media-control]"
    );

    this.updateLiveProgram();
  }

  /**
   * Update current program.
   * @method setLiveProgram
   * @param {Object} program the program data.
   * @return {LiveProgramMediaControl} itself
   */
  setLiveProgram(program) {
    console.log("new program data", program);
    this._setProgram(program);
    this.updateLiveProgram();
    return this;
  }

  updateLiveProgram() {
    console.log("update program");
    if (!this.$liveProgram || !this._program) {
      console.log("no liveprogram -> return");
      return;
    }

    this.$startAt.html(this._program.startAt);
    this.$progressBarFill.css({ width: this._program.progress + "%" });
    this.$endAt.html(this._program.endAt);
    this.$liveProgram.attr("title", this._program.title);

    // Bar is not displayed if program title is unavailable
    if (this._program.title.length === 0) {
      console.log("liveprogram -> hide");
      this.$liveProgram.hide();
    } else {
      this.$liveProgram.show();
      console.log("liveprogram -> show", this._program);
    }
  }

  // @override
  bindEvents() {
    super.bindEvents();

    this.listenTo(
      this.core.mediaControl,
      Events.MEDIACONTROL_RENDERED,
      this.render
    );
    // this.listenTo(this.core, Events.CORE_FULLSCREEN, (evt) => {
    //   // Ensure resizeLiveProgram() is called after browser entered
    //   // or exited fullscreen mode
    //   process.nextTick(() => {
    //     this.resizeLiveProgram();
    //   });
    // });
  }

  // @override
  updateVolumeUI() {
    super.updateVolumeUI();
    if (!this.rendered) {
      return;
    }
    this.resizeLiveProgram();
  }

  // @override
  changeTogglePlay() {
    super.changeTogglePlay();
    this.resizeLiveProgram();
  }

  // @override
  highDefinitionUpdate(isHD) {
    super.highDefinitionUpdate(isHD);
    this.resizeLiveProgram();
  }

  // @override
  playerResize(size) {
    super.playerResize(size);
    this.resizeLiveProgram(size);
  }

  // @override
  updateProgressBar(progress) {
    if (this._seekBarHidden) {
      return;
    }
    super.updateProgressBar(progress);
  }

  // @override
  renderSeekBar() {
    if (this._seekBarHidden) {
      return;
    }
    // Hide default seek bar if playback is live without DVR
    // if (this.isLiveWithoutDVR) {
    this.$seekBarScrubber.hide();
    this.$seekBarContainer.hide();
    this.resizeLiveProgram();
    this._seekBarHidden = this._setSeekBarHidden;
    // } else {
    //   super.renderSeekBar()
    // }
  }

  // @override
  render() {
    super.render();
    console.log("render?");
    this._seekBarHidden = false;
    this.renderLiveProgramBar();

    return this;
  }
}
