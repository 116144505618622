import React, { Component } from "react";
import { ReactComponent as Logo } from "../transmite_live.svg";
import ovn from "../ovn_white.svg";

class HomePage extends Component {
  state = {};

  componentDidMount() {
    document.body.classList.toggle("animation", true);
  }
  render() {
    return (
      <div className="container h-100 animated">
        <div className="row align-items-center h-100">
          <div className="col-md-12 homeWrap">
            <Logo className="bigLogo" />
            <p>O platforma video</p>
            <a
              href="https://www.ovn.ro"
              target="_blank"
              rel="noopener noreferrer"
              className="ovnLogo"
            >
              <img src={ovn} alt="OVN" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
