import React, { Component } from "react";
import "./Page.css";
import axios from "axios";
import VideoComponent from "./utils/player";
import ChatBox from "./utils/chatbox";
import MDSpinner from "react-md-spinner";

import md5 from "md5";

class VideoPage extends Component {
  state = {
    video: {},
    isLoading: true,
    publicPswd: "",
    locked: true,
    username: "",
    confirm: false,
    password: "",
    email: "none",
    phone: "none",
    error: "",
    embed: false,
  };

  componentDidMount() {
    document.body.classList.toggle("animation", false);

    let url;
    if (this.props.match.params.videoid) {
      url =
        "https://manager.myovn.com/get/account/" +
        this.props.match.params.username +
        "/videos/" +
        this.props.match.params.videoid;
    } else {
      url =
        "https://manager.myovn.com/get/account/" +
        this.props.match.params.username +
        "/" +
        this.props.match.params.permalink;
    }

    //check if is embed

    if (
      this.props.match.params.embed &&
      this.props.match.params.embed === "embed"
    ) {
      this.setState({ embed: true });
    }

    axios
      .get(url)
      .then((res) => {
        const video = res.data;

        document.title = video.title + " | transmite.live";

        let locked = false;
        if (
          video.public_pass ||
          video.public_login ||
          video.public_access ||
          video.public_registration
        ) {
          locked = true;
        }

        if (
          sessionStorage.getItem("access") &&
          sessionStorage.getItem("access") === md5(video._id)
        ) {
          locked = false;
        }

        if (video.loadbalancer) {
          // if (!sessionStorage.getItem("loadbalancer")) {
          axios
            .get("//balancer.myovn.com")
            .then((url) => {
              // console.log(url);
              sessionStorage.setItem("loadbalancer", url.data);
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.log(error.response);
              //failsafe if balancer is down or error
              sessionStorage.setItem("loadbalancer", "eu.myovn.com");
              this.setState({ isLoading: false });
            });
          // }
        }

        //update backgound Image  or Color
        if (video.owner && video.owner.style.background !== 1) {
          document.body.style.background = `#e8e8e8  url('//s1.myovn.com/images/_bg/bg${video.owner.style.background}.jpg')  top center no-repeat fixed`;
          document.body.style.color = video.owner.style.color;
          document.body.style.backgroundSize = "cover";
        }
        if (video.owner.style.bgcolor && video.owner.style.bgcolor !== "") {
          document.body.style.background = video.owner.style.bgcolor;
        }

        var head = document.head;
        var link = document.createElement("link");

        link.type = "text/css";
        link.rel = "stylesheet";
        link.href =
          "https://transmite.live/css/" + video.owner.username + ".css";

        head.appendChild(link);

        // console.log("is locked?",locked);

        this.setState({
          video,
          locked,
          isLoading: false,
          publicPswd: video.public_pass,
        });
      })
      .catch(function (error) {
        // history.push("/");
        console.log(error);
      });
  }

  keyUpRegistration = (e) => {
    e.preventDefault();

    if (e.key === "Enter") {
      console.log("click");

      this.checkRegistration();
    }
  };

  checkRegistration = () => {
    let urlcheck =
      "https://manager.myovn.com/get/account/" +
      this.props.match.params.username +
      "/videos/" +
      this.state.video._id +
      "/checkpass/" +
      this.state.username +
      "/" +
      md5(this.state.password) +
      "/" +
      this.state.email +
      "/" +
      this.state.phone;

    // console.log(urlcheck);

    axios
      .get(urlcheck)
      .then((res) => {
        // console.log(res);
        sessionStorage.setItem("access", md5(this.state.video._id));
        sessionStorage.setItem("name", this.state.username);
        this.setState({ locked: false, error: "" });
      })
      .catch((error) => {
        // console.log("login failed!");
        this.setState({ error: "Autentificare esuata!" });
      });
  };

  checkLogin = () => {
    // console.log(md5(this.state.password));
    // return;
    let urlcheck =
      "https://manager.myovn.com/get/account/" +
      this.props.match.params.username +
      "/videos/" +
      this.state.video._id +
      "/auth/" +
      this.state.username +
      "/" +
      md5(this.state.password);

    // console.log(urlcheck);

    axios
      .get(urlcheck)
      .then((res) => {
        console.log(res);
        sessionStorage.setItem("access", md5(this.state.video._id));
        // sessionStorage.setItem("name", this.state.username);
        sessionStorage.setItem("name", res.data.user);
        this.setState({ locked: false, error: "" });
      })
      .catch((error) => {
        // console.log("login failed!");
        this.setState({ error: "Autentificare esuata!" });
      });
  };

  checkAccess = () => {
    // console.log(md5(this.state.password));
    // return;
    let urlcheck =
      "https://manager.myovn.com/get/account/" +
      this.props.match.params.username +
      "/videos/" +
      this.state.video._id +
      "/access/" +
      this.state.password;

    // console.log(urlcheck);

    axios
      .get(urlcheck)
      .then((res) => {
        console.log(res);
        sessionStorage.setItem("access", md5(this.state.video._id));
        // sessionStorage.setItem("name", this.state.username);
        // sessionStorage.setItem("name", res.data.user);
        this.setState({ locked: false, error: "" });
      })
      .catch((error) => {
        // console.log("login failed!");
        this.setState({ error: "Autentificare esuata!" });
      });
  };
  // addMsg = (msg) => {
  //   let video = this.state.video;
  //   video.chat.push(msg);
  //   // var msgIndex = video.chat.findIndex((x) => x._id == msg.mesaj._id);
  //   // video.chat[msgIndex] = msg.mesaj;
  //   console.log(video.chat);
  //   this.setState({ video });
  // };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onConfirm = (event) => {
    this.setState({ confirm: event.target.checked });
  };

  renderRegistration = () => {
    return (
      <React.Fragment>
        <div>
          <div className=" col-md-12">
            <div className="card-body">
              <form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <h6 className="card">Inregistrare</h6>
                <br />

                <div className="form-group">
                  <label>Numele dvs.</label>
                  <input
                    onChange={this.onChange}
                    type="text"
                    className="form-control"
                    name="username"
                  />
                </div>
                <div className="row">
                  <div className=" col-md-12 col-xs-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className=" col-md-12 col-xs-12">
                    <div className="form-group">
                      <label>Telefon</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label>Access Code</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onKeyUp={this.keyUpRegistration}
                    onChange={this.onChange}
                  />
                </div> */}
                {this.state.video.public_tos.length > 0 && (
                  <div
                    className="card disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: this.state.video.public_tos,
                    }}
                  ></div>
                )}
                <div className="form-group">
                  <div className="chiller_cb">
                    <input
                      name="confirm"
                      id="myCheckbox"
                      defaultChecked={this.state.confirm}
                      type="checkbox"
                      onChange={this.onConfirm}
                    />
                    <label htmlFor="myCheckbox">
                      Sunt de acord cu Termenii si Conditiile
                    </label>
                    <span>
                      <i className="fa fa-checked" />
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  id="sendlogin"
                  disabled={
                    // !this.state.password
                    !this.state.username ||
                    !this.state.email ||
                    !this.state.phone ||
                    !this.state.confirm
                  }
                  onClick={this.checkRegistration}
                  className="btn chatButtonAdd"
                >
                  Autentificare
                </button>
                {this.state.error && (
                  <p className="small">{this.state.error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLogin = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body">
              <form action="" autoComplete="off">
                {/* <h6 className="card">Autentificare</h6>
                <br /> */}

                {this.state.video.public_tos.length > 0 && (
                  <div
                    className="card disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: this.state.video.public_tos,
                    }}
                  ></div>
                )}

                <div className="row">
                  <div className=" col-md-6 col-xs-6">
                    <div className="form-group">
                      <label>User</label>
                      <input
                        onChange={this.onChange}
                        type="text"
                        className="form-control"
                        name="username"
                      />
                    </div>
                  </div>
                  <div className=" col-md-6 col-xs-6">
                    <div className="form-group">
                      <label>Parola</label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="chiller_cb">
                        <input
                          name="confirm"
                          id="myCheckbox"
                          defaultChecked={this.state.confirm}
                          type="checkbox"
                          onChange={this.onConfirm}
                        />
                        <label htmlFor="myCheckbox">
                          Sunt de acord cu Termenii si Conditiile
                        </label>
                        <span>
                          <i className="fa fa-checked" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  id="sendlogin"
                  disabled={
                    !this.state.password ||
                    !this.state.username ||
                    !this.state.confirm
                  }
                  onClick={this.checkLogin}
                  className="btn chatButtonAdd"
                >
                  Autentificare
                </button>
                {this.state.error && (
                  <p className="small">{this.state.error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderAccess = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card-body">
              <form action="" autoComplete="off">
                <h6 className="card">VIDEO RESTRICTIONAT</h6>
                <br />

                {this.state.video.public_tos.length > 0 && (
                  <div
                    className="card disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: this.state.video.public_tos,
                    }}
                  ></div>
                )}

                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                      <label style={{ textAlign: "center" }}>
                        Introduceti codul de access. <br />
                        <span>Nu ai cod? </span>
                        <a href="https://fraudsummit.ro" target="_blank">
                          Inregistrează-te
                        </a>
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  id="sendlogin"
                  disabled={!this.state.password}
                  onClick={this.checkAccess}
                  className="btn chatButtonAdd"
                >
                  Autentificare
                </button>
                {this.state.error && (
                  <p className="small">{this.state.error}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderVideo = () => {
    const { video, embed } = this.state;

    return (
      <React.Fragment>
        {/* <h3>{video.title}</h3>
        <hr /> */}
        <div className="row">
          <div className="col-md-12">
            <VideoComponent video={video} embed={embed} />
          </div>
          <div className="col-md-12">
            {video.chat_enabled && (
              <ChatBox
                chat_public={video.chat_public}
                videoid={video._id}
                videoowner={video.owner.username}
                user={this.state.username}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { video, isLoading, locked, embed } = this.state;

    const LoadingHtml = (
      <div className="alignCenter" style={{ height: "100vh" }}>
        <div className="text-center">
          <MDSpinner size={60} borderSize={10} />
          <br />
          <br />
          <h5>Loading video. Please wait.</h5>
        </div>
      </div>
    );

    if (embed) {
      return (
        <React.Fragment>
          {isLoading && LoadingHtml}
          {!isLoading && locked && (
            <div className="alignCenter" style={{ height: "100vh" }}>
              <div className="text-center" style={{ width: "100%" }}>
                <div className="row pageWrap">
                  <div className="col-md-8 offset-md-2 offset-sm-0 card">
                    {this.renderAccess()}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isLoading && !locked && (
            <VideoComponent
              video={video}
              embed={embed}
              location={this.props.location.search}
            />
          )}
        </React.Fragment>
      );
    }

    if (isLoading) {
      return LoadingHtml;
    }

    return (
      <div className="container h-100">
        {/* <link rel="stylesheet" type="text/css" href={`/${video.owner.username}.css`} /> */}

        <div className="row pageWrap">
          {locked && video.public_registration && (
            <div className=" col-sm-12 col-md-6 offset-md-3 offset-sm-0 card">
              {this.renderRegistration()}
            </div>
          )}
          {locked && video.public_login && (
            <div className="col-md-6 offset-md-3 offset-sm-0 card">
              {this.renderLogin()}
            </div>
          )}

          {locked && video.public_access && (
            <div className="col-md-6 offset-md-3 offset-sm-0 card">
              {this.renderAccess()}
            </div>
          )}

          {!locked && (
            <div className="col-md-10 offset-md-1 card">
              {this.renderVideo(this.props)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default VideoPage;
