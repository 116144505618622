import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import socketIOClient from "socket.io-client";
import { Scrollbars } from "react-custom-scrollbars";
import axios from "axios";

Moment.globalMoment = moment;
Moment.globalLocale = "en";

var socket;
class ChatBox extends Component {
  state = {
    textmsg: "",
    user: "",
    editName: false,
    comments: [],
  };

  setName = (event) => {
    if (event.key === "Enter") {
      sessionStorage.setItem("name", event.target.value);
      this.setState({ editName: false });
    }
  };

  setNameBtn = () => {
    sessionStorage.setItem("name", this.state.user);
    this.setState({ editName: false });
  };

  handleKeyUp = (event) => {
    if (event.key === "Enter") {
      this.addMsg(this.state.textmsg);
    }
  };

  addMsg = (msg) => {
    let user = sessionStorage.getItem("name");
    socket.emit("savemesaj", {
      video: this.props.videoid,
      mesaj: msg,
      user,
      publish: this.props.chat_public,
    });
    this.setState({ textmsg: "" });
  };

  listMsg = (msg, i) => {
    let hasUser = msg.user && msg.user !== "" ? ` by ${msg.user}` : ``;
    return (
      <div key={i}>
        <span className="chatItem">{msg.mesaj}</span>
        <span className="chatItemExtra">
          <Moment fromNow>{msg.date}</Moment>
          {hasUser}
        </span>
      </div>
    );
  };

  UpdateMsgs = (data) => {
    console.log("incoming", data);
    // this.props.addMsg(data);
    let comments = this.state.comments;
    comments.push(data);
    this.setState({ comments });
    this.scrollToBottom();
  };

  onChange = (event) => {
    //console.log(event.target.value);
    this.setState({ textmsg: event.target.value });
  };

  onChangeName = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  componentDidMount() {
    // this.scrollToBottom();

    let url;
    url =
      "//manager.myovn.com/get/account/" +
      this.props.videoowner +
      "/videos/" +
      this.props.videoid +
      "/comments";

    axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        this.setState({ comments: res.data });
      })
      .catch(function (error) {
        // history.push("/");
        console.log("Error - can't get comments!".error);
      });

    this.setState({ user: sessionStorage.getItem("name") });
    socket = socketIOClient("https://manager.myovn.com");
    socket.emit("register", {
      video: this.props.videoid,
      firstTime: false,
      user: sessionStorage.getItem("name"),
    });
    // socket.on("hello", data => console.log(data));
    socket.on("mesaj", (data) => {
      // console.log("primit ", data);
      this.UpdateMsgs(data);
    });
  }

  componentDidUpdate() {
    if (this.props.messages && this.props.messages.length > 0) {
      this.scrollbar.scrollToBottom();
    }
  }

  render() {
    const { comments } = this.state;

    const name = sessionStorage.getItem("name");

    return (
      <React.Fragment>
        <br></br>
        <h3>
          ADRESEAZA INTREBARI INVITATILOR
          {comments.length > 0 ? " (" + comments.length + " mesaje)" : ""}
        </h3>
        <div className="row m-1">
          <div className="col-md-12 card chatWrapper">
            {comments && comments.length > 0 && (
              <div className="chatMsg">
                <Scrollbars
                  style={{ width: "100%", height: 250 }}
                  ref={(scrollbar) => {
                    this.scrollbar = scrollbar;
                  }}
                >
                  {comments.map((item, index) => this.listMsg(item, index))}
                  <div
                    style={{ float: "left", clear: "both" }}
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  />
                </Scrollbars>
              </div>
            )}
            <hr />
            <div className="chatAdd">
              <div className="row">
                {/* <div className="col-md-6 chatText">
                  Mesajul va aparea dupa ce va fi moderat!
                </div> */}
                <div className="col-md-6 chatName">
                  {!this.state.editName && (
                    <>
                      <p>
                        Nume: <b>{name}</b>
                      </p>
                      <button
                        className="btn chatEditBtn"
                        onClick={() => this.setState({ editName: true })}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                    </>
                  )}
                  {this.state.editName && (
                    <>
                      <div className="editName">
                        <p>
                          <input
                            onChange={this.onChangeName}
                            onKeyUp={this.setName}
                            type="text"
                            className="form-control"
                            name="user"
                            value={this.state.user}
                          />
                        </p>
                      </div>
                      <button className="btn " onClick={this.setNameBtn}>
                        <i className="fa fa-save"></i>
                      </button>
                    </>
                  )}
                </div>
              </div>
              <textarea
                className="form-control"
                name="textmsg"
                value={this.state.textmsg}
                placeholder="Scrie un mesaj si apasa ENTER!"
                onChange={this.onChange}
                onKeyUp={this.handleKeyUp}
              />

              <button
                className="pull-right btn chatButtonAdd"
                disabled={!this.state.textmsg}
                onClick={() => this.addMsg(this.state.textmsg)}
              >
                Trimite Mesaj
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatBox;
