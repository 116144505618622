import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import socketIOClient from "socket.io-client";

import axios from "axios";

Moment.globalMoment = moment;
Moment.globalLocale = "en";

var socket;
class ChatEdit extends Component {
  state = {
    data: [],
    user: "",
  };

  aprobaMSG(mesaj, index) {
    mesaj.publish = !mesaj.publish;
    console.log(mesaj);
    let data = this.state.data;
    data[index] = mesaj;
    this.setState({ data });
    socket.emit("update", mesaj);
   
    
  }

  componentDidMount() {
    // this.scrollToBottom();

   
    let url;
    if (this.props.match.params.videoid) {
      url =
        "https://manager.myovn.com/get/account/" +
        this.props.match.params.username +
        "/videos/" +
        this.props.match.params.videoid+"/allcomments";
    } 

    axios
      .get(url)
      .then((res) => {
        // const video = res.data;
        console.log(res.data);
        this.setState({
          data: res.data,
        });
      })
      .catch(function (error) {
        // history.push("/");
        console.log(error);
      });

    this.setState({ user: sessionStorage.getItem("name") });
    socket = socketIOClient("https://manager.myovn.com");
    console.log("emit register to ", this.props.match.params.videoid);
    socket.emit("register", {
      video: this.props.match.params.videoid,
      firstTime: false,
      user: "Test",
    });
    // socket.on("lista", (data) => console.log(data));
    // socket.on("delete", (data) => console.log(data));
    // socket.on("newmesaj", (data) => this.addMesaj(data));
  }

 

  render() {
    const {data}= this.state;

    if (!data) return null;
    return (
      <div className="container h-100">
        <br></br><h3><center>Comments moderation</center></h3>
        {data.map((chat, index) => (
          <div className="row moderateRow" key={chat._id}>
            <div className="col-md-1 card">{index+1}</div>
            <div className="col-md-7  card text">{chat.mesaj}</div>
            <div className="col-md-2  card">
              {chat.user}
              <br /><Moment fromNow>{chat.date}</Moment> 
            </div>
            <div className="col-md-2  card">
              
                <button
                  className="btn chatButtonAdd"
                  onClick={() => this.aprobaMSG(chat, index)}
                >
                 {!chat.publish?"APROBA":"RETRAGE"}
                </button>
            
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ChatEdit;
