import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import HomePage from "./components/HomePage";
import VideoPage from "./components/VideoPage";
import PlaylistPage from "./components/PlaylistPage";
import ChannelPage from "./components/ChannelPage";
import ChatEdit from "./components/utils/chatEdit";
import ChatOnline from "./components/utils/chatonline";

class App extends Component {
  render() {
    const NoMatch = () => <Redirect to="/" />;
    return (
      <Switch>
        <Route
          path="/:username/channel/:channelId/:embed"
          component={ChannelPage}
        />
        <Route path="/:username/channel/:channelId" component={ChannelPage} />
        <Route
          path="/:username/playlist/:playlistid/:page/:embed"
          render={(props) => (
            <PlaylistPage key={props.match.params.page} {...props} />
          )}
        />
        <Route
          path="/:username/playlist/:playlistid/:page"
          render={(props) => (
            <PlaylistPage key={props.match.params.page} {...props} />
          )}
        />
        <Route
          path="/:username/playlist/:playlistid"
          component={PlaylistPage}
        />
       <Route path="/:username/video/:videoid/chat" component={ChatEdit} />

        <Route path="/:username/video/:videoid/:embed" component={VideoPage} />

        <Route path="/:username/video/:videoid" component={VideoPage} />

        <Route path="/:username/:permalink/chat" component={ChatEdit} />
        <Route path="/:username/:permalink/online" component={ChatOnline} />
        <Route path="/:username/:permalink/:embed" component={VideoPage} />

        <Route path="/:username/:permalink" component={VideoPage} />
        <Route exact path="/" component={HomePage} />
        <Route component={NoMatch} />
      </Switch>
    );
  }
}

export default App;
