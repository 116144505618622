import React, { Component } from "react";
import "./Page.css";
import axios from "axios";
import VideoComponent from "./utils/player";
import MDSpinner from "react-md-spinner";

class ChannelPage extends Component {
  state = {
    video: {},
    isLoading: true,
    embed: false
  };

  componentDidMount() {
    document.body.classList.toggle("animation", false);
    const { history } = this.props;

    let url;
    if (this.props.match.params.channelId) {
      url =
        "https://manager.myovn.com/get/account/" +
        this.props.match.params.username +
        "/channels/" +
        this.props.match.params.channelId;
    }
    //check if is embed
    if (
      this.props.match.params.embed &&
      this.props.match.params.embed === "embed"
    ) {
      this.setState({ embed: true });
    }

    console.log(url);
    axios
      .get(url)
      .then(res => {
        const video = res.data;
        console.log(video);
        video.owner.branding = {};

        let branding = {};

        branding.b_player_autostart = video.c_branding.c_player_autostart;

        branding.b_player_controls = video.c_branding.c_player_controls;
        branding.branding_enabled = video.c_active_branding;
        branding.b_logo_url = video.c_logo_url;
        branding.b_logo_position = video.c_logo_position;
        branding.b_logo_link = video.c_logo_link;
        branding.b_player_volume = video.c_player_volume;

        video.owner.branding = { ...branding };

        video.title = video.c_title;
        video.type = "channel";
        video.state = "complete";

        document.title = video.title + " | transmite.live";

        this.setState({
          video,
          isLoading: false
        });
      })
      .catch(function(error) {
        history.push("/");
        // console.log(error);
      });
  }

  renderVideo = () => {
    const { video } = this.state;
    return (
      <React.Fragment>
        <h3>{video.title}</h3>
        <hr />
        <VideoComponent video={video} />
      </React.Fragment>
    );
  };

  render() {
    const { video, isLoading, embed } = this.state;
    const LoadingHtml = (
      <div className="alignCenter" style={{ height: "100vh" }}>
        <div className="text-center">
          <MDSpinner size={60} borderSize={10} />
          <br />
          <br />
          <h5>Loading channel. Please wait.</h5>
        </div>
      </div>
    );

    if (embed) {
      return (
        <React.Fragment>
          {isLoading && LoadingHtml}
          {!isLoading && <VideoComponent video={video} embed={embed} />}
        </React.Fragment>
      );
    }

    if (isLoading) {
      return LoadingHtml;
    }

    return (
      <div className="container h-100">
        <div className="row pageWrap">
          <div className="col-md-12 card">{this.renderVideo()}</div>
        </div>
      </div>
    );
  }
}

export default ChannelPage;
