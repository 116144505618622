import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment/min/moment-with-locales";
import socketIOClient from "socket.io-client";

// import axios from "axios";

Moment.globalMoment = moment;
Moment.globalLocale = "en";

var socket;
class ChatOnline extends Component {
  state = {
    lista: [],
  };

  componentDidMount() {
    // this.scrollToBottom();

    this.setState({ user: sessionStorage.getItem("name") });
    socket = socketIOClient("https://manager.myovn.com");

    socket.emit("register", {
      video: "5f7ea1889b4f13ca54b7c006",
      firstTime: false,
      user: "Test",
    });
    socket.on("lista", (data) => this.setState({ lista: data }));
    socket.on("delete", (data) => this.setState({ lista: data }));
    // socket.on("newmesaj", (data) => this.addMesaj(data));
  }

  componentDidUpdate() {}

  render() {
    let lista = this.state.lista;

    if (!lista) return null;
    return (
      <div className="container h-100">
        <center>
          <h4>Total {lista.length}</h4>
        </center>
        <div className="row pageWrap">
          <div className="col-md-6  offset-md-3 card">
            {lista.map((item, index) => (
              <div key={item.sock}>{item.user}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ChatOnline;
